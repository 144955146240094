































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({ name: 'MultiSelect' })
export default class MultiSelect extends Vue {
  @Prop()
  value!: any[]

  @Prop()
  items!: any[]

  get selectedItems() {
    return this.value
  }
  set selectedItems(items: any[]) {
    this.$emit('input', items)
    this.$emit('change', items)
  }

  get icon() {
    if (this.isSelectedAll) return 'mdi-checkbox-marked'
    if (this.isSelectedSomeItems) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get isSelectedAll() {
    return this.value.length === this.items.length
  }

  get isSelectedSomeItems() {
    return this.value.length > 0 && !this.isSelectedAll
  }

  toggle() {
    this.$nextTick(() => {
      if (this.isSelectedAll) {
        this.selectedItems = []
      } else {
        const itemValue = this.$attrs['item-value'] || this.$attrs.itemValue
        if (itemValue) {
          this.selectedItems = this.items.map(item => item[itemValue])
        } else {
          this.selectedItems = [...this.items]
        }
      }
    })
  }
}
