



















import {Component, Prop, Vue} from "vue-property-decorator";

@Component({ name: 'CopyButton' })
export default class CopyButton extends Vue {
  @Prop()
  textToCopy!: string

  showTooltip: boolean = false

  copyToClipboard() {
    navigator.clipboard.writeText(this.textToCopy)
    this.showTooltip = true
    setTimeout(() => {
      this.showTooltip = false
    }, 1000)
  }
}
