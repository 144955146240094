


































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {getObjectValueByPath} from '../utils/universalUtils';

@Component({name: 'CollapseCombobox'})
export default class CollapseCombobox extends Vue {
  @Prop()
  value!: any[];

  @Prop()
  items!: any[];

  @Prop()
  itemText!: string;

  @Prop()
  itemValue!: string

  @Prop()
  translate!: boolean;

  @Emit('change')
  onChange(value: any) {
    return value;
  }

  @Emit('input')
  onInput(value: any) {
    return value.map((item:any) => {
      if (typeof (item) !== "object") {
        return item
      }
      return item[this.itemValue || 'id']
    });
  }

  get isSelectedAll() {
    return this.value.length === this.items.length
  }

  get isSelectedSomeItems() {
    return this.value.length > 0 && !this.isSelectedAll
  }

  get icon() {
    if (this.isSelectedAll) return 'mdi-checkbox-marked'
    if (this.isSelectedSomeItems) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  isItemSelected(item: any) {
    return this.value.some(el => el === item[this.itemValue || 'id'])
  }

  translateText(obj: object) {
    const value = this.getValue(this.itemText, obj);

    return this.translate ? this.$t(`SYSTEM_${value}`) : value;
  }

  getValue(path: string, obj: object) {
    if (typeof (obj) !== 'object') {
      return obj
    }
    return getObjectValueByPath(path, obj);
  }

  selectAllItems() {
    this.$emit('input', [...this.items])
  }

  toggle() {
    this.$nextTick(() => {
      if (this.isSelectedAll) {
        this.$emit('input', [])
      } else {
        this.$emit('input', this.items.map(item => item[this.itemValue || 'id']))
      }
    })
  }
}
