import IStockMovement from '@/types/IStockMovement';

export default class StockMovementFactory {
  static toStockMovement(data: any): IStockMovement {
    return {
      date: data?.date?.replace('T', ' ').substring(0, 10),
      fulfillmentCenterId: data.fulfillmentCenterId,
      quantity: data.quantity,
      sku: data.sku,
      type: data.type,
      asin: data.asin,
      name: data.name,
      url: data.url,
    };
  }
}
