import IStockMovementFilters from '@/types/IStockMovementFilters';
import StockMovementRepository from '@/repositories/StockMovementRepository';
import IStockMovement from '@/types/IStockMovement';
import StockMovementFactory from '@/factories/StockMovementFactory';
import FileService from '@/services/FileService';

export default class StockMovementService {
    static async getAll(filters: IStockMovementFilters)
        : Promise<{ stockMovements: IStockMovement[], totalElements: number }> {
        const {data} = await StockMovementRepository.getAll(filters);

        return {
            stockMovements: data.content.map((item: any) => StockMovementFactory.toStockMovement(item)),
            totalElements: data.totalElements,
        };
    }

    static async downloadFile(filters: IStockMovementFilters) {
        const {data} = await StockMovementRepository.download(filters);

        const file = new FileService(data);
        file.downloadFile('Stock-Movements');
    }

    static async getFilters(): Promise<{ typeList: string[], skuList: string[], fulfillmentCenterIdList: string[] }> {
        const {data} = await StockMovementRepository.getFilters();

        return {
            typeList: data.typeList,
            skuList: data.skuList,
            fulfillmentCenterIdList: data.fulfillmentCenterIdList
        };
    }
}
