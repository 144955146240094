








































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {getObjectValueByPath} from '../utils/universalUtils';
import MultiSelect from '@/components/MultiSelect.vue';

@Component({name: 'CollapseMultiSelect',
  components: {MultiSelect}
})
export default class CollapseMultiSelect extends Vue {
  @Prop()
  value!: any[];

  @Prop()
  itemText!: string;

  @Prop()
  translate!: boolean;

  @Emit('change')
  onChange(value: any) {
    return value;
  }

  @Emit('input')
  onInput(value: any) {
    return value;
  }

  isSelected(item: any) {
    const value = this.$attrs['item-value'] || this.$attrs.itemValue

    return this.value.includes(value ? item[value] : item)
  }

  translateText(obj: object) {
    const value = this.getValue(this.itemText, obj);

    return this.translate ? this.$t(`SYSTEM_${value}`) : value;
  }

  getValue(path: string, obj: object) {
    return getObjectValueByPath(path, obj);
  }
}
