

















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import {IDataTablePagination} from '@/types/IPagination';
import IStockMovement from '@/types/IStockMovement';
import IStockMovementFilters from '@/types/IStockMovementFilters';
import CopyButton from "@/components/CopyButton.vue";

@Component({name: 'StockMovementTable',
  components: {CopyButton}
})
export default class StockMovementTable extends Vue {
  @Prop()
  data!: IStockMovement[];

  @Prop()
  params!: IStockMovementFilters;

  @Prop()
  loading!: boolean;

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_DATE',
      value: 'date',
    },
    {
      text: 'SYSTEM_FULFILLMENT_CENTER_ID',
      value: 'fulfillmentCenterId',
    },
    {
      text: 'SYSTEM_TYPE',
      value: 'type'
    },
    {
      text: 'SYSTEM_QUANTITY',
      value: 'quantity'
    },
  ];

  @Emit('fetchData')
  emitFetchData() {
    return;
  }

  @Emit('paginationChanged')
  paginationChanged(pagination: IDataTablePagination) {
    return pagination;
  }

  @Emit('sort')
  sort(value: { sortBy: string[], sortDesc: string[] }) {
    this.params.sortBy = value.sortBy[0];
    this.params.sortDirection = value.sortDesc[0] ? 'DESC' : 'ASC';
    return this.params;
  }

}
