








































import {Component, Vue} from 'vue-property-decorator';
import {resolveError} from '@/utils/notifications';
import IStockMovementFilters from '@/types/IStockMovementFilters';
import {IDataTablePagination} from '@/types/IPagination';
import IStockMovement from '@/types/IStockMovement';
import StockMovementService from '@/services/StockMovementService';
import Filters from '@/components/StockMovement/Filters.vue';
import StockMovementTable from '@/components/StockMovement/StockMovementTable.vue';
import {getSavedPageData, savePageData} from '@/utils/pageDataSaver';
import PageWrap from '@/components/PageWrap.vue';

@Component({
  name: 'StockMovements',
  components: {
    PageWrap,
    StockMovementTable,
    Filters
  },
})
export default class StockMovements extends Vue {
  stockMovements: IStockMovement[] = [];
  filters: IStockMovementFilters = {
    skuList: [],
    fulfillmentCenterIdList: [],
    typeList: [],
    dateFrom: null,
    dateTo: null,
    quantityFrom: null,
    quantityTo: null,
    page: 1,
    size: 10,
    totalElements: 0,
  };

  skuList: string[] = [];
  fulfillmentCenterIdList: string[] = [];
  typeList: string[] = [];

  loading: boolean = false;

  onFiltersChanged(filters: IStockMovementFilters) {
    this.filters = {...this.filters, ...filters};
  }

  paginationChanged(pagination: IDataTablePagination) {
    this.filters.page = pagination.page;
    this.filters.size = pagination.itemsPerPage;

    this.fetchData();
  }

  sortChanged(sortable: IStockMovementFilters) {
    this.filters.sortBy = sortable.sortBy;
    this.filters.sortDirection = sortable.sortDirection;

    this.fetchData();
  }

  created() {
    this.getSavedFilters()
    this.fetchFiltersData();
  }

  getSavedFilters() {
    const { filters } = getSavedPageData()
    if (filters) {
      this.filters = filters
    }
  }

  async fetchFiltersData() {
    try {
      const {
        typeList,
        skuList,
        fulfillmentCenterIdList
      } = await StockMovementService.getFilters();
      this.typeList = typeList;
      this.skuList = skuList;
      this.fulfillmentCenterIdList = fulfillmentCenterIdList;
    } catch (e) {
      resolveError(e, 'fetch_filters');
    }
  }

  async fetchData() {
    try {
      this.loading = true;
      const {
        stockMovements,
        totalElements,
      } = await StockMovementService.getAll(this.filters);
      savePageData({
        filters: this.filters
      })
      this.stockMovements = stockMovements;
      this.filters.totalElements = totalElements;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }
}
