import axios from 'axios';
import IStockMovementFilters from '@/types/IStockMovementFilters';

export default class StockMovementRepository {
  static getAll(filters: IStockMovementFilters) {
    return axios.post(`/stock/movements/all`, filters);
  }

  static download(filters: IStockMovementFilters) {
    return axios.post(`/stock/movements/export`, filters, {responseType: 'blob'});
  }

  static getFilters() {
    return axios.get(`/stock/movements/forms`);
  }
}
