export default class FileService {
    file!: File;

    constructor(file: File) {
        this.file = file;
    }

    downloadFile(filename: string) {
        const blob = new Blob([this.file], {type: this.file.type});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }

    // downloadReport(response) {
    //   const header = response.headers['content-disposition']
    //   let name = header?.slice(header.indexOf('=') + 1, header.length).toString() || 'Report'
    //   if (name.startsWith('"')) {
    //     name = name.replace('"', '')
    //   }
    //   this.downloadFile(name)
    // }
}
