









import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'DebounceInput'})
export default class DebounceInput extends Vue {
  @Prop({default: 350})
  delay!: number;

  @Prop()
  value!: string | number;

  debounce: NodeJS.Timeout | null = null;

  @Emit('change')
  emit() {
    return;
  }

  onSearchChange(value: string | number) {
    this.$emit('input', value);
    if (!value) {
      this.emit();
      if (this.debounce) {
        clearTimeout(this.debounce);
      }
    } else {
      if (this.debounce) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        this.emit();
      }, this.delay);
    }
  }

}
